import * as Yup from "yup";
import FormikBase from "../../common/components/FormikBase";

export default class Domain extends FormikBase {
  static readonly SchemaWithValidations = Yup.object().shape({
    SerialNumber: Yup.string()
      .required("Required.")
      .default("")
      .matches(/^\d+$/, "Must be numeric only."),
    PartNumber: Yup.string()
      .required("Required.")
      .default("5500050000")
      .matches(/^\d+$/, "Must be numeric only.")
      .length(10, "Should be 10 digits long only."),
    Date: Yup.date()
      .required("Required.")
      .typeError("Enter date in MMM/dd/yyyy format.")
      .default(new Date())
  });
}

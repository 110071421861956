import { FormikProps } from "formik";
import _ from "lodash";

export default class FormikBase {
  static FFPS: any = {}; //FormikFieldPropertiesSpread
  static InitializeFFPS(
    props: FormikProps<any>,
    objWithStateProps: any,
    skipErrorFFPForArray: any,
    skipHelperTextFFPForArray: any
  ) {
    objWithStateProps &&
      Object.keys(objWithStateProps).forEach(property => {
        FormikBase.FFPS[property] = {
          id: property,
          name: property,
          onChange: props.handleChange,
          onBlur: props.handleBlur,
          value: Object(props.values)[property],
          error: Boolean(
            Object(props.errors)[property] && Object(props.touched)[property]
          ),
          helperText:
            Object(props.errors)[property] &&
            Object(props.touched)[property] &&
            Object(props.errors)[property]
        };

        skipErrorFFPForArray &&
          skipErrorFFPForArray.includes(property) &&
          delete FormikBase.FFPS[property].error;

        skipHelperTextFFPForArray &&
          skipHelperTextFFPForArray.includes(property) &&
          delete FormikBase.FFPS[property].helperText;
      });
  }

  static SetFieldErrorsFromApi(error: any, values: any, actions: any) {
    error.response.data.forEach((errorObject: any) => {
      actions.setFieldError(
        _.filter(Object.keys(values), property => {
          return (
            property.toLowerCase() === errorObject.propertyName.toLowerCase()
          );
        })[0],
        errorObject.errorMessage
      );
    });
  }
}

import { ApiCalls } from "../../constants";
import axios from "axios";
import { FormikActions } from "formik";
import { dispatch } from "../global-state/State";
import { CurrentUser_Get } from "../components/CurrentUser";

var intentionalLag: any;
axios.defaults.withCredentials=true;
export default async function ApiOperation(
  operationName: string,
  values: {},
  processActions: boolean,
  actions: FormikActions<{}>,
  postApiOperationExecutionHandler: (isSuccess: boolean, data: any) => void
) {
  switch (operationName) {
    case ApiCalls.SignUp: {
      return await axios
        .post(ApiCalls.SignUp, values, {
          headers: {
             'Content-Type': 'application/json',
             'Accept': 'application/json',
             'X-Requested-With': 'XMLHttpRequest',
             'Access-Control-Allow-Credentials':'true'
          },withCredentials:true})
        .then(response => {
          dispatch({
            type: "showApiOperationFinalDialog",
            IsError: false,
            Title: "Success",
            Message:
              "Thank you for signing up with us. We've sent an OTP to your registered email address. Please use it on account verification screen to complete the sign up.",
            DialogClosureHandler: () => {
              dispatch({ type: "closeApiOperationFinalDialog" });
              InvoksPostApiOperation(
                postApiOperationExecutionHandler,
                true,
                response
              );
            }
          });
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
    case ApiCalls.InviteAdministrator: {
      return await axios
        .post(ApiCalls.InviteAdministrator, values, {
          headers: {
            Authorization: "Bearer " + CurrentUser_Get().Token
          },withCredentials:true
        })
        .then(response => {
          dispatch({
            type: "showApiOperationFinalDialog",
            IsError: false,
            Title: "Success",
            Message:
              "Invitation sent successfully. " +
              (values as any).FirstName +
              " can sign in as administrator now.",
            DialogClosureHandler: () => {
              dispatch({ type: "closeApiOperationFinalDialog" });
              InvoksPostApiOperation(
                postApiOperationExecutionHandler,
                true,
                response
              );
            }
          });
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
    case ApiCalls.ResendVerification: {
      return await axios
        .patch(ApiCalls.ResendVerification, values,{withCredentials:true})
        .then(response => {
          dispatch({
            type: "showApiOperationFinalDialog",
            IsError: false,
            Title: "Success",
            Message:
              "Thank you for signing up with us. We've resent an OTP to your registered email address. Please use it on account verification screen to complete the sign up.",
            DialogClosureHandler: () => {
              dispatch({ type: "closeApiOperationFinalDialog" });
              InvoksPostApiOperation(
                postApiOperationExecutionHandler,
                true,
                response
              );
            }
          });
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
    case ApiCalls.SignIn: {
      return await axios
        .post(ApiCalls.SignIn, values, {
          headers: {
             'Content-Type': 'application/json',
             'Accept': 'application/json',
             'X-Requested-With': 'XMLHttpRequest',
             'Access-Control-Allow-Credentials':'true'
          },withCredentials:true})
        .then(response => {
          InvoksPostApiOperation(
            postApiOperationExecutionHandler,
            true,
            response
          );
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
    case ApiCalls.GetCustomers: {
      return await axios
        .get(ApiCalls.GetCustomers, {
          headers: {
            Authorization: "Bearer " + CurrentUser_Get().Token
          },withCredentials:true
        })
        .then(response => {
          InvoksPostApiOperation(
            postApiOperationExecutionHandler,
            true,
            response
          );
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          if (processActions) {
            actions.setSubmitting(false);
          }
        });
    }
    case ApiCalls.ApproveCustomer: {
      return await axios
        .patch(ApiCalls.ApproveCustomer, values, {
          headers: {
            Authorization: "Bearer " + CurrentUser_Get().Token
          },withCredentials:true
        })
        .then(response => {
          InvoksPostApiOperation(
            postApiOperationExecutionHandler,
            true,
            response
          );
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          if (processActions) {
            actions.setSubmitting(false);
          }
        });
    }
    case ApiCalls.RecoverPassword: {
      return await axios
        .patch(ApiCalls.RecoverPassword, values,{withCredentials:true})
        .then(response => {
          dispatch({
            type: "showApiOperationFinalDialog",
            IsError: false,
            Title: "Success",
            Message:
              "We've sent an OTP to your registered email address. Please use it on password reset screen to set a new password for your account.",
            DialogClosureHandler: () => {
              dispatch({ type: "closeApiOperationFinalDialog" });
              InvoksPostApiOperation(
                postApiOperationExecutionHandler,
                true,
                response
              );
            }
          });
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          if (processActions) {
            actions.setSubmitting(false);
          }
        });
    }
    case ApiCalls.ResetPassword: {
      return await axios
        .patch(ApiCalls.ResetPassword, values,{withCredentials:true})
        .then(response => {
          dispatch({
            type: "showApiOperationFinalDialog",
            IsError: false,
            Title: "Success",
            Message:
              "Password has been reset successfully. Please sign in using your new password.",
            DialogClosureHandler: () => {
              dispatch({ type: "closeApiOperationFinalDialog" });
              InvoksPostApiOperation(
                postApiOperationExecutionHandler,
                true,
                response
              );
            }
          });
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          if (processActions) {
            actions.setSubmitting(false);
          }
        });
    }
    case ApiCalls.GenerateKey: {
      return await axios
        .post(ApiCalls.GenerateKey, values, {
          headers: {
            Authorization: "Bearer " + CurrentUser_Get().Token
          },withCredentials:true
        })
        .then(response => {
          InvoksPostApiOperation(
            postApiOperationExecutionHandler,
            true,
            response
          );
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          if (processActions) {
            actions.setSubmitting(false);
          }
        });
    }
    case ApiCalls.VerifyAccount: {
      return await axios
        .patch(ApiCalls.VerifyAccount, values,{withCredentials:true})
        .then(response => {
          dispatch({
            type: "showApiOperationFinalDialog",
            IsError: false,
            Title: "Success",
            Message:
              "Account has been verified successfully. You'll receive an email to your registered email address once your account is approved. You can sign in using your registered email and password if your account is approved.",
            DialogClosureHandler: () => {
              dispatch({ type: "closeApiOperationFinalDialog" });
              InvoksPostApiOperation(
                postApiOperationExecutionHandler,
                true,
                response
              );
            }
          });
        })
        .catch(error => {
          processApiErrors(
            error,
            processActions,
            actions,
            postApiOperationExecutionHandler,
            error
          );
        })
        .finally(() => {
          if (processActions) {
            actions.setSubmitting(false);
          }
        });
    }
  }
}

function processApiErrors(
  error: any,
  processActions: boolean,
  actions: FormikActions<{}>,
  postApiOperationExecutionHandler: (isSuccess: boolean, data: any) => void,
  dataForPostApiOperationExecutionHandler: any
) {
  if (error.response && error.response.status === 400) {
    if (processActions) {
      error.response.data.Errors.forEach((errorObject: any) => {
        actions.setFieldError(
          errorObject.PropertyName,
          errorObject.ErrorMessage
        );
      });
    }

    var dialogMessage = "";
    if (!processActions) {
      error.response.data.Errors.forEach((errorObject: any) => {
        if (dialogMessage.length > 0) {
          dialogMessage += ", & ";
        }
        dialogMessage +=
          errorObject.PropertyName + ": " + errorObject.ErrorMessage;
      });
    }

    dispatch({
      type: "showApiOperationFinalDialog",
      IsError: true,
      Title: "Error",
      Message:
        dialogMessage.length > 0
          ? dialogMessage
          : "Please correct the form errors.",
      DialogClosureHandler: () => {
        dispatch({ type: "closeApiOperationFinalDialog" });
        InvoksPostApiOperation(
          postApiOperationExecutionHandler,
          false,
          dataForPostApiOperationExecutionHandler
        );
      }
    });
  } else if (error.response && error.response.status === 401) {
    dispatch({
      type: "showApiOperationFinalDialog",
      IsError: true,
      Title: "Unauthorized",
      Message:
        "Looks like you're not authorized to perform this operation or your session has expired. Please sign out and close all browser windows and then try again after some time. In case the problem persists or in case of any issues, please contact us using the help page.",
      DialogClosureHandler: () => {
        dispatch({ type: "closeApiOperationFinalDialog" });
        InvoksPostApiOperation(
          postApiOperationExecutionHandler,
          false,
          dataForPostApiOperationExecutionHandler
        );
      }
    });
  } else {
    dispatch({
      type: "showApiOperationFinalDialog",
      IsError: true,
      Title: "Error",
      Message:
        "An error has occurred while processing your request. We're working on the resolution of the same. Please try again after some time.",
      DialogClosureHandler: () => {
        dispatch({ type: "closeApiOperationFinalDialog" });
        InvoksPostApiOperation(
          postApiOperationExecutionHandler,
          false,
          dataForPostApiOperationExecutionHandler
        );
      }
    });
  }
}

function InvoksPostApiOperation(
  postApiOperationExecutionHandler: (isSuccess: boolean, data: any) => void,
  isSuccess: boolean,
  data: any
) {
  clearTimeout(intentionalLag);

  intentionalLag = setTimeout(() => {
    postApiOperationExecutionHandler(isSuccess, data);
  }, 10); //To prevent sudden hang in chrome when screens with password field are stopped by chrome from redirection so that chrome can save passwords.
}

import * as Yup from "yup";
import FormikBase from "../../common/components/FormikBase";

export default class Domain extends FormikBase {
  static readonly SchemaWithValidations = Yup.object().shape({
    FirstName: Yup.string()
      .required("Required.")
      .matches(
        /^[a-z A-Z 0-9 _']+$/,
        "FirstName can only use letters, numbers, underscores, and periods."
      ) 
      .default(""),
    LastName: Yup.string()
      .required("Required.")
       .matches(
        /^[a-z A-Z 0-9 _']+$/,
        "LastName can only use letters, numbers, underscores, and periods."
      ) 
      .default(""),
    Email: Yup.string()
      .email("Must be a valid email.")
      .required("Required.")
     .matches(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,"Invalid Email address.")
     .default(""),
    Password: Yup.string()
      .required("Required.")
      .matches(
        /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
      )
      .default(""),
    ConfirmPassword: Yup.string()
      .required("Required.")
      .oneOf([Yup.ref("Password")], "Passwords must match.")
      .default(""),
    Organization: Yup.string()
      .required("Required.")
      .default(""),
    StrykerContact: Yup.string()
      .required("Required.")
      .default(""),
    NotesComments: Yup.string().default(""),
    AllowExtraEmails: Yup.boolean().default(true)
  });
}

import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";
import { useGlobalState } from "../global-state/State";

export default function ApiOperationFinalDialog() {
  const [ApiOperationFinalDialogState] = useGlobalState(
    "ApiOperationFinalDialogState"
  );

  return (
    <Dialog
      open={ApiOperationFinalDialogState.IsOpen}
      aria-labelledby="responsive-dialog-title"
      onClose={ApiOperationFinalDialogState.DialogClosureHandler}
    >
      <DialogTitle>
        <div>
          <Typography
            component="h6"
            variant="h6"
            color={ApiOperationFinalDialogState.IsError ? "error" : "primary"}
          >
            {ApiOperationFinalDialogState.Title}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom align="justify" display="block">
          {ApiOperationFinalDialogState.Message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ApiOperationFinalDialogState.DialogClosureHandler}
          color={ApiOperationFinalDialogState.IsError ? "secondary" : "primary"}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AppPaths, ApiCalls } from "../constants";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import history from "../common/components/CreateBrowserHistory";
import { useGlobalState, dispatch } from "../common/global-state/State";
import { CurrentUser_Get } from "../common/components/CurrentUser";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

var intentionalLag: any;

export default function SignOut() {
  const classes = useStyles();
  const [SignOutMsg, setSignOutMsg] = useState("Signing out, please wait...");

  useEffect(() => {
    if (CurrentUser_Get().IsLoggedIn) {
      clearTimeout(intentionalLag);
      intentionalLag = setTimeout(() => {
        window.sessionStorage.clear();
        setSignOutMsg("Signed out, please close the browser!");
      }, 500);
    } else {
      setSignOutMsg("Signed out, please close the browser!");
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Out
        </Typography>
        <br />
      </div>
      {CurrentUser_Get().IsLoggedIn && (
        <LinearProgress variant="query" color="primary" />
      )}
      <Grid container item justify="center" xs={12}>
        <Typography variant="subtitle1" color="primary">
          {SignOutMsg}
        </Typography>
      </Grid>
      {CurrentUser_Get().IsLoggedIn && <LinearProgress color="primary" />}
    </Container>
  );
}

import React, { useRef, useState, Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AppPaths, ApiCalls } from "../constants";
import { Formik } from "formik";
import Domain from "./ducks/Domain";
import { CircularProgress } from "@material-ui/core";
import history from "../common/components/CreateBrowserHistory";
import ApiOperation from "../common/api-operations/ApiOperations";
import { useGlobalState } from "../common/global-state/State";
import { dispatch } from "../common/global-state/State";
import ReCAPTCHA, { } from "react-google-recaptcha";
import axios from "axios";
//import { AppConfiguration } from "read-appsettings-json"

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(15)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

export default function SignUp() {
    const classes = useStyles();
    const captchaRef = useRef(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [ip, setIP] = useState('');
    axios.defaults.withCredentials = true;
    /*  alert(AppConfiguration.Setting().ClientKey);*/
    //var public_key = String(AppConfiguration.Setting().ClientKey);
    var public_key = String(process.env.REACT_APP_PUBLIC);
    // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


    let state = { isVerified: false };
    let token = "";
    const handleOnchange = (value: any) => {

        state = { isVerified: true };
        // alert("Hi" + value + "   value  " + state.isVerified);
        token = value;
    }

  const [ApiOperationFinalDialogState] = useGlobalState(
    "ApiOperationFinalDialogState"
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Formik
          initialValues={Domain.SchemaWithValidations.default()}
                  onSubmit={async (values, actions) => {


                     


                      //await axios.post("https://www.google.com/recaptcha/api/siteverify?secret=" + secretedkey + "&response=" + token)
                     


            function handleApiResponse(isSuccess: boolean, data: any) {
              if (isSuccess) {
                actions.resetForm();
                history.push(AppPaths.VerifyAccount);
              }
            }


                      if (token.length === 0) {

                          dispatch({
                              type: "showApiOperationFinalDialog",
                              IsError: false,
                              Title: "Error",
                              Message:
                                  "Captcha is required ",
                              DialogClosureHandler: () => {
                                  dispatch({ type: "closeApiOperationFinalDialog" });

                              }
                          });
                          actions.setSubmitting(false);




                      } else {
                          axios.post("api/user/recaptcha?token=" + token)
                              //await axios.post("https://www.google.com/recaptcha/api/siteverify", { token })
                              .then(res => console.log(res))
                              .catch((error) => {
                                  console.log(error);
                              })

                          await ApiOperation(
                              ApiCalls.SignUp,
                              values,
                              true,
                              actions,
                              handleApiResponse
                          );
                      }
          }}
          validationSchema={Domain.SchemaWithValidations}
        >
          {props => {
            const {
              errors,
              dirty,
              isSubmitting,
              handleSubmit,
              handleReset
            } = props;

            Domain.InitializeFFPS(
              props,
              Domain.SchemaWithValidations.default(),
              ["AllowExtraEmails"],
              ["AllowExtraEmails"]
            );

            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["FirstName"]}
                      label="First Name"
                      autoComplete="fname"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["LastName"]}
                      label="Last Name"
                      autoComplete="lname"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...Domain.FFPS["Email"]}
                      label="Email Address"
                      autoComplete="email"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["Password"]}
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["ConfirmPassword"]}
                      label="Confirm Password"
                      type="password"
                      autoComplete="current-password"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["Organization"]}
                      label="Organization"
                      autoComplete="organization"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["StrykerContact"]}
                      label="Stryker Contact"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...Domain.FFPS["NotesComments"]}
                      label="Notes/Comments"
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                        </Grid>
              {/*          <ReCAPTCHA sitekey={String(process.env.REACT_APP_PUBLIC)} ref={captchaRef} onChange={handleOnchange} />*/}

                        <ReCAPTCHA sitekey={public_key} ref={captchaRef} onChange={handleOnchange} />

                        <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...Domain.FFPS["AllowExtraEmails"]}
                          color="primary"
                          checked={props.values["AllowExtraEmails"]}
                        />
                      }
                      label={
                        <Typography variant="caption">
                          I'd like to receive updates and promotions via email.
                        </Typography>
                      }
                    />
                        </Grid>

                    </Grid>
                 

                    {/*<div className="form-group">*/}
                    {/*    <label>Recaptcha Validation</label>*/}
                    {/*    <Recaptcha*/}
                    {/*        sitekey="6Le2nREUAAAAALYuOv7X9Fe3ysDmOmghtj0dbCKW"*/}
                    {/*        render="explicit"*/}
                    {/*        theme="dark"*/}
                    {/*        verifyCallback={(response) => { setFieldValue("recaptcha", response); }}*/}
                    {/*        onloadCallback={() => { console.log("done loading!"); }}*/}
                    {/*    />*/}
                    {/*    {errors.recaptcha*/}
                    {/*        && touched.recaptcha && (*/}
                    {/*            <p>{errors.recaptcha}</p>*/}
                    {/*        )}*/}
                    {/*</div>*/}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.submit}
                      disabled={
                        (ApiOperationFinalDialogState.IsError &&
                          Object.keys(Object(errors)).length > 0) ||
                        isSubmitting
                      }
                    >
                      {!isSubmitting && <div>Sign Up</div>}
                      {isSubmitting && <CircularProgress size={25} />}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="reset"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.submit}
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link to={AppPaths.SignIn} className={classes.link}>
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
}

import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AppPaths, ApiCalls } from "../constants";
import { Formik } from "formik";
import Domain from "./ducks/Domain";
import {
  CircularProgress,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  DialogTitle,
  InputLabel,
  Card
} from "@material-ui/core";
import history from "../common/components/CreateBrowserHistory";
import ApiOperation from "../common/api-operations/ApiOperations";
import { useGlobalState } from "../common/global-state/State";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as dateFnsFormat from "date-fns/format";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(15)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

export default function KeyGenerator() {
  const classes = useStyles();
  // const theme = useTheme();
  const [ApiOperationFinalDialogState] = useGlobalState(
    "ApiOperationFinalDialogState"
  );

  const [
    TemporarayPasswordKeyDialogIsOpen,
    setTemporarayPasswordKeyDialogIsOpen
  ] = useState(false);
  const [TemporarayPasswordKey, setTemporarayPasswordKey] = useState("");
  const [
    AllowCalendarKeyboardControl,
    setAllowCalendarKeyboardControl
  ] = useState(false);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Key Generator
        </Typography>
        <Formik
          initialValues={Domain.SchemaWithValidations.default()}
          onSubmit={async (values, actions) => {
            function handleApiResponse(isSuccess: boolean, data: any) {
              if (isSuccess) {
                setTemporarayPasswordKey(data.data.Data[0].Key);
                setTemporarayPasswordKeyDialogIsOpen(true);
              }
            }

            await ApiOperation(
              ApiCalls.GenerateKey,
              values,
              true,
              actions,
              handleApiResponse
            );
          }}
          validationSchema={Domain.SchemaWithValidations}
        >
          {props => {
            const {
              errors,
              dirty,
              values,
              setFieldValue,
              isSubmitting,
              handleSubmit,
              handleReset
            } = props;

            Domain.InitializeFFPS(
              props,
              Domain.SchemaWithValidations.default(),
              null,
              null
            );

            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["SerialNumber"]}
                      label="Serial Number"
                      autoComplete="fname"
                      variant="outlined"
                      required
                      fullWidth
                      // autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...Domain.FFPS["PartNumber"]}
                      label="Part Number"
                      autoComplete="fname"
                      variant="outlined"
                      required
                      fullWidth
                      inputProps={{
                        maxLength: 10
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Container maxWidth="xs">
                      <Paper>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            {...Domain.FFPS["Date"]}
                            label="Date"
                            required
                            variant="static"
                            onChange={value => {
                              setFieldValue("Date", value);
                            }}
                            animateYearScrolling={false}
                            allowKeyboardControl={AllowCalendarKeyboardControl}
                            onFocus={() => {
                              setAllowCalendarKeyboardControl(true);
                            }}
                            onBlur={() => {
                              setAllowCalendarKeyboardControl(false);
                            }}
                            fullWidth
                          />
                        </MuiPickersUtilsProvider>
                      </Paper>
                    </Container>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.submit}
                      disabled={
                        (ApiOperationFinalDialogState.IsError &&
                          Object.keys(Object(errors)).length > 0) ||
                        isSubmitting
                      }
                    >
                      {!isSubmitting && <div>Generate</div>}
                      {isSubmitting && <CircularProgress size={25} />}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="reset"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.submit}
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={TemporarayPasswordKeyDialogIsOpen}
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      minWidth: "325px",
                      maxWidth: "325px"
                    }
                  }}
                  BackdropProps={{
                    style: {
                      background: "rgba(0,0,0,0.9)"
                    }
                  }}
                  // transitionDuration={{ enter: 1000, exit: 1000 }}
                  fullWidth
                  onClose={() => {
                    setTemporarayPasswordKeyDialogIsOpen(false);
                  }}
                >
                  <Box
                    border={2}
                    borderColor="primary.main"
                    borderRadius={25}
                    style={{ backgroundColor: "white" }}
                  >
                    <DialogTitle>
                      <div>
                        <Typography align="center" variant="h4" color="primary">
                          <strong>Test Sonopet</strong>
                        </Typography>
                      </div>
                    </DialogTitle>

                    <DialogContent dividers>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography noWrap>Serial Number:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="primary">
                            {values.SerialNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography noWrap>Part Number:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="primary">
                            {values.PartNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />
                      <br />
                      <Container>
                        <div>
                          <Typography variant="h6" noWrap>
                            <strong>Temporary Password</strong>
                          </Typography>
                        </div>
                      </Container>
                      <Container>
                        <div>
                          <Typography align="center" noWrap color="secondary">
                            {dateFnsFormat.default(
                              values.Date,
                              "MMMM, do yyyy"
                            )}
                          </Typography>
                        </div>
                      </Container>
                      <br />
                      <Container
                        maxWidth="xs"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <Card>
                          <Typography
                            align="center"
                            variant="h3"
                            noWrap
                            color="primary"
                          >
                            <strong>{TemporarayPasswordKey}</strong>
                          </Typography>
                        </Card>
                      </Container>
                      <br />
                      <br />
                      <br />
                      <Typography
                        variant="subtitle1"
                        color="error"
                        align="justify"
                        display="block"
                      >
                        <strong>
                          Please do not use the Sonopet Demonstration Mode
                          during a surgical procedure.
                        </strong>
                      </Typography>
                    </DialogContent>

                    <DialogActions>
                      <Button
                        onClick={() => {
                          setTemporarayPasswordKeyDialogIsOpen(false);
                        }}
                        color={"primary"}
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Box>
                </Dialog>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
}

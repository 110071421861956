import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
// import { withRouter } from "react-router";
import IconLogo from "../../common/images/logo.png";
import { useGlobalState, dispatch } from "../../common/global-state/State";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    footerAppBar: {
      top: "auto",
      bottom: 0
    },
    list: {
      width: 200
    }
  })
);

function Header(props: any) {
  const classes = useStyles();
  const [SwipeableSonopetDrawerState] = useGlobalState(
    "SwipeableSonopetDrawerState"
  );
  // const openSignUpPage = () => {
  //   props.history.push(AppPaths.SignUp);
  // };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={() => {
              dispatch({
                type: "setSwipeableSonopetDrawerState",
                IsOpen: !SwipeableSonopetDrawerState.IsOpen
              });
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            KeyGen
          </Typography>
          <img src={IconLogo} alt="stryker" />
        </Toolbar>
      </AppBar>
    </div>
  );
}

//export default withRouter(Header);
export default Header;

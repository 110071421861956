import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

export default function Help(props: any) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography align="justify" display="block">
          {/* For any help, please send a mail to{" "}
          <a href="mailto:sonopettestmailtest@stryker.com">
            sonopettestmailtest@stryker.com
          </a>{" "}
          from your registered email address (with your account details) and
          detailed issue description. We'll revert to your mail as soon as
          possible. */}
          For any help, please get in touch with your Stryker Contact or Stryker
          Information Services.
        </Typography>
      </div>
    </Container>
  );
}

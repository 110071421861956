import React from "react";
import MaterialTable from "material-table";
import { Formik, FormikActions } from "formik";
import Domain from "./ducks/Domain";
import { useGlobalState, dispatch } from "../common/global-state/State";
import {
    LinearProgress,
    IconButton,
    CircularProgress,
    Tooltip
} from "@material-ui/core";
import ApiOperation from "../common/api-operations/ApiOperations";
import { ApiCalls } from "../constants";
import _ from "lodash";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref as any} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref as any} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref as any} />),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref as any} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref as any} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref as any} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref as any} />),
    Filter: forwardRef((props, ref) => (
        <FilterList {...props} ref={ref as any} />
    )),
    FirstPage: forwardRef((props, ref) => (
        <FirstPage {...props} ref={ref as any} />
    )),
    LastPage: forwardRef((props, ref) => (
        <LastPage {...props} ref={ref as any} />
    )),
    NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref as any} />
    )),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref as any} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref as any} />
    )),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref as any} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowUpward {...props} ref={ref as any} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref as any} />
    )),
    ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref as any} />
    ))
};

export class CustomersListTypeEnum {
    static readonly ALL = 1;
    static readonly PENDING_APPROVAL = 2;
    // static readonly APPROVED = 3;
    // static readonly DISABLED = 4;
}

export default function CustomersList(props: any) {
    const [Customers] = useGlobalState("Customers");
    const CustomersListType = props.CustomersListType;

    return (
        <Formik
            initialValues={Domain.SchemaWithValidations.default()}
            onSubmit={async (values, actions) => {
                function handleApiResponse(isSuccess: boolean, data: any) {
                    if (isSuccess) {
                    }
                }

                // await ApiOperation(
                //   ApiCalls.GetCustomers,
                //   values,
                //   actions,
                //   handleApiResponse
                // );
            }}
            validationSchema={Domain.SchemaWithValidations}
        >
            {props => {
                const {
                    errors,
                    dirty,
                    isSubmitting,
                    handleSubmit,
                    handleReset,
                    submitForm
                } = props;

                Domain.InitializeFFPS(
                    props,
                    Domain.SchemaWithValidations.default(),
                    null,
                    null
                );

                return (
                    <form onSubmit={handleSubmit} id="CustomersListForm">
                        <div style={{ maxWidth: "100%" }}>
                            {!Customers.AreLoaded && (
                                <div>
                                    <LinearProgress variant="query" />
                                    <LinearProgress />
                                    <LinearProgress variant="query" />
                                </div>
                            )}
                            <MaterialTable
                                icons={tableIcons}
                                columns={[
                                    { title: "Id", field: "Id", hidden: true },
                                    { title: "First Name", field: "FirstName" },
                                    { title: "Last Name", field: "LastName" },
                                    { title: "Email", field: "Email" },
                                    { title: "Organization", field: "Organization" },
                                    { title: "SYK Contact", field: "StrykerContact" },
                                    {
                                        title: "Is Approved",
                                        field: "IsApproved",
                                        type: "boolean",
                                        headerStyle: {
                                            textAlign: "center"
                                        },
                                        cellStyle: {
                                            textAlign: "center"
                                        }
                                    },
                                    {
                                        title: "Is Disabled",
                                        field: "IsDeleted",
                                        type: "boolean",
                                        headerStyle: {
                                            textAlign: "center"
                                        },
                                        cellStyle: {
                                            textAlign: "center"
                                        }
                                    },
                                    {
                                        title: "",
                                        field: "IsApproving",
                                        type: "boolean",
                                        emptyValue: "0",
                                        hidden: true
                                    },
                                    {
                                        title: "",
                                        field: "IsJustApproved",
                                        type: "boolean",
                                        emptyValue: "0",
                                        hidden: true
                                    }
                                ]}
                                data={
                                    CustomersListType === CustomersListTypeEnum.PENDING_APPROVAL
                                        ? Customers.Data.filter(
                                            customer => !(customer as any).IsApproved
                                        )
                                        : Customers.Data
                                }
                                title=""
                                options={{
                                    // actionsColumnIndex: -1,
                                    search: true,
                                    toolbar: true,
                                    pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000]
                                    // searchFieldAlignment: "left"
                                    // loadingType: "linear"
                                    // headerStyle: {
                                    //   backgroundColor: "#545857",
                                    //   color: "#000000"
                                    // }
                                    // rowStyle: rowData => ({
                                    //   backgroundColor: "#545857"
                                    // })
                                }}
                                actions={
                                    CustomersListType != CustomersListTypeEnum.PENDING_APPROVAL
                                        ? []
                                        : [
                                            {
                                                icon: "person_add",
                                                onClick: (event, rowData) => {
                                                    //Progress starts
                                                    rowData.IsApproving = "1";
                                                    dispatch({
                                                        type: "loadCustomers",
                                                        Customers: Customers.Data
                                                    });

                                                    //Api call
                                                    ApiOperation(
                                                        ApiCalls.ApproveCustomer,
                                                        { Id: rowData.Id },
                                                        false,
                                                        {} as FormikActions<{}>,
                                                        (isSuccess: boolean, data: any) => {
                                                            rowData.IsApproving = "0";
                                                            if (isSuccess) {
                                                                rowData.IsJustApproved = "1";
                                                            }
                                                            dispatch({
                                                                type: "loadCustomers",
                                                                Customers: Customers.Data
                                                            });
                                                        }
                                                    );
                                                }
                                            }
                                        ]
                                }
                                components={{
                                    Action: props => (
                                        <div>
                                            {props.data.IsApproving == "1" ? (
                                                <IconButton disabled={true}>
                                                    <CircularProgress size={26} />
                                                </IconButton>
                                            ) : props.data.IsJustApproved ? (
                                                <IconButton disabled={true}>
                                                    <DoneAllIcon />
                                                </IconButton>
                                            ) : (
                                                        <Tooltip title="Approve (click to approve)">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={event =>
                                                                    props.action.onClick(event, props.data)
                                                                }
                                                            >
                                                                <PersonAddIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                        </div>
                                    )
                                }}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: Customers.AreLoaded
                                            ? "No records to display"
                                            : "Loading..."
                                    }
                                }}
                            />
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
}

import jsonwebtoken from "jsonwebtoken";

export function CurrentUser_Login(token: string) {
  window.sessionStorage.setItem("SonopetKeyGenToken", token);
}

export function CurrentUser_Get() {
  var user: any = {
    IsLoggedIn: false,
    Id: 0,
    FirstName: "",
    LastName: "",
    Email: "",
    Organization: "",
    StrykerContact: "",
    NotesComments: "",
    Roles: [],
    Token: ""
  };
  if (window.sessionStorage.getItem("SonopetKeyGenToken")) {
    var decodedToken: any = jsonwebtoken.decode(
      window.sessionStorage.getItem("SonopetKeyGenToken")!
    );

    var userData: any = JSON.parse(
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"
      ]
    );
    user = {
      IsLoggedIn: true,
      Id: userData.Id,
      FirstName: userData.FirstName,
      LastName: userData.LastName,
      Email: userData.Email,
      Organization: userData.Organization,
      StrykerContact: userData.StrykerContact,
      NotesComments: userData.NotesComments,
      Roles:
        decodedToken.role.constructor === Array
          ? decodedToken.role
          : [decodedToken.role],
      Token: window.sessionStorage.getItem("SonopetKeyGenToken")
    };
  }
  return user;
}

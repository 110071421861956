import * as Yup from "yup";
import FormikBase from "../../common/components/FormikBase";

export default class Domain extends FormikBase {
  static readonly SchemaWithValidations = Yup.object().shape({
    Email: Yup.string()
      .email("Must be a valid email.")
      .required("Required.")
      .default("")
  });
}

export class AppPaths {
  static readonly Root = "/";
  static readonly SignIn = "/signin";
  static readonly SignOut = "/signout";
  static readonly SignUp = "/signup";
  static readonly KeyGenerator = "/keygenerator";
  static readonly ManageCustomers = "/managecustomers";
  static readonly RecoverPassword = "/recoverpassword";
  static readonly ResetPassword = "/resetpassword";
  static readonly VerifyAccount = "/verifyaccount";
  static readonly ResendVerification = "/resendverification";
  static readonly InviteAdministrator = "/inviteadministrator";
  static readonly Help = "/help";
}


export class ApiCalls {
  static readonly SignUp = "api/user/signup";
  static readonly SignIn = "api/user/signin";
  static readonly GenerateKey = "api/sonopetkey/generatekey";
  static readonly GetCustomers = "api/user/getcustomers";
  static readonly ApproveCustomer = "api/user/approvecustomer";
  static readonly RecoverPassword = "api/user/recoverpassword";
  static readonly ResetPassword = "api/user/resetpassword";
  static readonly VerifyAccount = "api/user/verifyaccount";
  static readonly ResendVerification = "api/user/resendverification";
  static readonly InviteAdministrator = "api/user/inviteadministrator";
}

import * as Yup from "yup";
import FormikBase from "../../common/components/FormikBase";

export default class Domain extends FormikBase {
  static readonly SchemaWithValidations = Yup.object().shape({
    Email: Yup.string()
      .email("Must be a valid email.")
      .required("Required.")
      .default(""),
    Password: Yup.string()
      .required("Required.")
      .matches(
        /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
      )
          .default("")//,
      //recaptcha: Yup.string().required("Required.")
      //    .default("");
  });
}

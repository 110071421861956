import React from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Route, Router, Switch, Redirect, RouteProps } from "react-router-dom";
import { AppPaths } from "../constants";
import SignIn from "../sign-in/SignIn";
import SignOut from "../sign-out/SignOut";
import SignUp from "../sign-up/SignUp";
import KeyGenerator from "../key-generator/KeyGenerator";
import ManageCustomers from "../manage-customers/ManageCustomers";
import Notfound from "../404/NotFound";
import Help from "../help/Help";
import history from "../common/components/CreateBrowserHistory";
import { GlobalStateProvider } from "../common/global-state/State";
import ApiOperationFinalDialog from "../common/api-operations/ApiOperationFinalDialog";
import RecoverPassword from "../recover-password/RecoverPassword";
import ResetPassword from "../reset-password/ResetPassword";
import InviteAdministrator from "../invite-administrator/InviteAdministrator";
import ResendVerification from "../resend-verification/ResendVerification";
import VerifyAccount from "../verify-account/VerifyAccount";
import SwipeableSonopetDrawer from "../common/components/SwipeableSonopetDrawer";
import { CurrentUser_Get } from "../common/components/CurrentUser";
import _ from "lodash";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "607b6197-2bd5-4d7a-ac38-5f1dd5e1aa01",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();

class RouterOutlet extends React.Component {
  render() {
    return (
      <GlobalStateProvider>
        <Router history={history}>
          <div>
            <Header />
            <SwipeableSonopetDrawer />
            <Switch>
              <Route exact path={AppPaths.Root} component={SignIn} />
              <Route path={AppPaths.SignIn} component={SignIn} />
              <PrivateRoute
                authed={(): boolean => {
                  return CurrentUser_Get().IsLoggedIn;
                }}
                path={AppPaths.SignOut}
                component={SignOut}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return !CurrentUser_Get().IsLoggedIn;
                }}
                path={AppPaths.SignUp}
                component={SignUp}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return CurrentUser_Get().IsLoggedIn;
                }}
                path={AppPaths.KeyGenerator}
                component={KeyGenerator}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return (
                    CurrentUser_Get().IsLoggedIn &&
                    CurrentUser_Get().Roles.some(
                      (role: any) => role.toLowerCase() === "administrator"
                    )
                  );
                }}
                path={AppPaths.ManageCustomers}
                component={ManageCustomers}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return (
                    CurrentUser_Get().IsLoggedIn &&
                    CurrentUser_Get().Roles.some(
                      (role: any) => role.toLowerCase() === "administrator"
                    )
                  );
                }}
                path={AppPaths.InviteAdministrator}
                component={InviteAdministrator}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return !CurrentUser_Get().IsLoggedIn;
                }}
                path={AppPaths.RecoverPassword}
                component={RecoverPassword}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return !CurrentUser_Get().IsLoggedIn;
                }}
                path={AppPaths.ResetPassword}
                component={ResetPassword}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return !CurrentUser_Get().IsLoggedIn;
                }}
                path={AppPaths.ResendVerification}
                component={ResendVerification}
              />
              <PrivateRoute
                authed={(): boolean => {
                  return !CurrentUser_Get().IsLoggedIn;
                }}
                path={AppPaths.VerifyAccount}
                component={VerifyAccount}
              />
              <Route path={AppPaths.Help} component={Help} />
              <Route component={Notfound} />
            </Switch>
            <Footer />
            <ApiOperationFinalDialog />
          </div>
        </Router>
      </GlobalStateProvider>
    );
  }
}

export interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  authed: () => boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  let { component: Component, authed, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => {
        return authed() ? (
          <Component {...props} />
        ) : (
          <Redirect to={AppPaths.SignIn} />
        );
      }}
    />
  );
};

export default withAITracking(reactPlugin, RouterOutlet);

import * as Yup from "yup";
import FormikBase from "../../common/components/FormikBase";

export default class Domain extends FormikBase {
  static readonly SchemaWithValidations = Yup.object().shape({
    Email: Yup.string()
      .email("Must be a valid email.")
      .required("Required.")
      .default(""),
    OneTimePassword: Yup.string()
      .required("Required.")
      .matches(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
        "Invalid OTP."
      )
      .default(""),
    Password: Yup.string()
      .required("Required.")
      .matches(
        /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
      )
      .default(""),
    ConfirmPassword: Yup.string()
      .required("Required.")
      .oneOf([Yup.ref("Password")], "Passwords must match.")
      .default("")
  });
}

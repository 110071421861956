import React, { useRef, useState, Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AppPaths, ApiCalls } from "../constants";
import { Formik } from "formik";
import Domain from "./ducks/Domain";
import { CircularProgress } from "@material-ui/core";
import history from "../common/components/CreateBrowserHistory";
import ApiOperation from "../common/api-operations/ApiOperations";
import { dispatch, useGlobalState } from "../common/global-state/State";
import ReCAPTCHA, { } from "react-google-recaptcha";
import axios from "axios";
//import { AppConfiguration } from "read-appsettings-json"
import { Public } from "@material-ui/icons";
const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(15)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

export default function ResetPassword() {
    const classes = useStyles();
    const captchaRef = useRef(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [ip, setIP] = useState('');
    axios.defaults.withCredentials = true;

    //var public_key = String(AppConfiguration.Setting().ClientKey);
    var public_key = String(process.env.REACT_APP_PUBLIC);

    // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


    let state = { isVerified: false };
    let token = "";
    const handleOnchange = (value: any) => {

        state = { isVerified: true };
        // alert("Hi" + value + "   value  " + state.isVerified);
        token = value;
    }

  const [ApiOperationFinalDialogState] = useGlobalState(
    "ApiOperationFinalDialogState"
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Formik
          initialValues={Domain.SchemaWithValidations.default()}
          onSubmit={async (values, actions) => {
            



                
              function handleApiResponse(isSuccess: boolean, data: any) {

              if (isSuccess) {
                history.push(AppPaths.SignIn);
              }
            }
              if (token.length === 0) {

                  dispatch({
                      type: "showApiOperationFinalDialog",
                      IsError: false,
                      Title: "Error",
                      Message:
                          "Captcha is required ",
                      DialogClosureHandler: () => {
                          dispatch({ type: "closeApiOperationFinalDialog" });

                      }
                  });
                  actions.setSubmitting(false);




              } else {

                  axios.post("api/user/recaptcha?token=" + token).then(
                      response => { console.log(response) }).catch(
                          err => { console.log(err) });
                  await ApiOperation(
                      ApiCalls.ResetPassword,
                      {
                          ...values,
                          RecoveryOtp: { OneTimePassword: values.OneTimePassword }
                      },
                      true,
                      actions,
                      handleApiResponse
                  );
              }
          }}
          validationSchema={Domain.SchemaWithValidations}
        >
          {props => {
            const {
              errors,
              dirty,
              isSubmitting,
              handleSubmit,
              handleReset
            } = props;

            Domain.InitializeFFPS(
              props,
              Domain.SchemaWithValidations.default(),
              null,
              null
            );
            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...Domain.FFPS["Email"]}
                      label="Email Address"
                      autoComplete="email"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      {...Domain.FFPS["OneTimePassword"]}
                      label="One Time Password (OTP)"
                      type="password"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={2}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Link
                      to={AppPaths.RecoverPassword}
                      className={classes.link}
                    >
                      Resend
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...Domain.FFPS["Password"]}
                      label="New Password"
                      type="password"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...Domain.FFPS["ConfirmPassword"]}
                      label="Confirm New Password"
                      type="password"
                      variant="outlined"
                      required
                      fullWidth
                    />
                        </Grid>
                        <ReCAPTCHA sitekey={public_key} ref={captchaRef} onChange={handleOnchange} />

                        {/*<ReCAPTCHA sitekey={String(process.env.REACT_APP_PUBLIC)}  ref={captchaRef} onChange={handleOnchange} />*/}

                        {/*<ReCAPTCHA sitekey="6Lca_PUhAAAAAHtFEDPcxaRjE_LpPOC76C1LVTQ6" ref={captchaRef} onChange={handleOnchange} />*/}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.submit}
                      disabled={
                        (ApiOperationFinalDialogState.IsError &&
                          Object.keys(Object(errors)).length > 0) ||
                        isSubmitting
                      }
                    >
                      {!isSubmitting && <div>Reset</div>}
                      {isSubmitting && <CircularProgress size={25} />}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="reset"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.submit}
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset Form
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Link to={AppPaths.SignIn} className={classes.link}>
                      Sign in
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={AppPaths.SignUp} className={classes.link}>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
}

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AppPaths, ApiCalls } from "../constants";
import { Formik } from "formik";
import Domain from "./ducks/Domain";
import { CircularProgress, Box } from "@material-ui/core";
import history from "../common/components/CreateBrowserHistory";
import ApiOperation from "../common/api-operations/ApiOperations";
import { useGlobalState, dispatch } from "../common/global-state/State";
import _ from "lodash";
import {
  CurrentUser_Login,
  CurrentUser_Get
} from "../common/components/CurrentUser";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(15)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

export default function SignIn() {
  if (CurrentUser_Get().IsLoggedIn) {
    history.push(AppPaths.KeyGenerator);
  }

  const classes = useStyles();

  const [ApiOperationFinalDialogState] = useGlobalState(
    "ApiOperationFinalDialogState"
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={Domain.SchemaWithValidations.default()}
          onSubmit={async (values, actions) => {
            function handleApiResponse(isSuccess: boolean, data: any) {
              if (isSuccess) {
                CurrentUser_Login(data.data.Data[0].Token);
                history.push(AppPaths.KeyGenerator);
              }
            }

            await ApiOperation(
              ApiCalls.SignIn,
              values,
              true,
              actions,
              handleApiResponse
            );
          }}
          validationSchema={Domain.SchemaWithValidations}
        >
          {props => {
            const {
              errors,
              dirty,
              isSubmitting,
              handleSubmit,
              handleReset
            } = props;

            Domain.InitializeFFPS(
              props,
              Domain.SchemaWithValidations.default(),
              null,
              null
            );
            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...Domain.FFPS["Email"]}
                      label="Email Address"
                      autoComplete="email"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...Domain.FFPS["Password"]}
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      variant="outlined"
                      required
                      fullWidth
                    />
                    <Typography variant="caption">
                      This should be stryker password if you're an
                      administrator.
                    </Typography>
                    <br />
                    <Typography align="right" variant="body2">
                      <Link
                        to={AppPaths.VerifyAccount}
                        className={classes.link}
                      >
                        {"Account verification pending? Verify Now"}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.submit}
                      disabled={
                        (ApiOperationFinalDialogState.IsError &&
                          Object.keys(Object(errors)).length > 0) ||
                        isSubmitting
                      }
                    >
                      {!isSubmitting && <div>Sign In</div>}
                      {isSubmitting && <CircularProgress size={25} />}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="reset"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.submit}
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Link
                      to={AppPaths.RecoverPassword}
                      className={classes.link}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={AppPaths.SignUp} className={classes.link}>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <br />
                <br />
                <Box
                  border={2}
                  borderColor="secondary.main"
                  borderRadius={5}
                  style={{ backgroundColor: "lightgrey" }}
                >
                  <Typography variant="body1" align="center">
                    This site works best on the latest versions of Chrome,
                    Safari, &amp; Firefox.
                  </Typography>
                </Box>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
}

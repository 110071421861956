export const initialState = {
    ApiOperationFinalDialogState: {
        IsOpen: false,
        IsError: false,
        Title: "",
        Message: "",
        DialogClosureHandler: () => { }
    },
    Customers: { Data: [], AreLoaded: false },
    SwipeableSonopetDrawerState: {
        IsOpen: false
    }
};

export type State = typeof initialState;

export type Action =
    | {
        type: "showApiOperationFinalDialog";
        IsError: boolean;
        Title: string;
        Message: string;
        DialogClosureHandler: () => void;
    }
    | { type: "closeApiOperationFinalDialog" }
    | { type: "loadCustomers"; Customers: any }
    | { type: "unloadCustomers" }
    | { type: "loadCustomers"; Customers: any }
    | { type: "setSwipeableSonopetDrawerState"; IsOpen: boolean };

export const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case "showApiOperationFinalDialog":
            return {
                ...state,
                ApiOperationFinalDialogState: {
                    ...state.ApiOperationFinalDialogState,
                    IsOpen: true,
                    IsError: action.IsError,
                    Title: action.Title,
                    Message: action.Message,
                    DialogClosureHandler: action.DialogClosureHandler
                }
            };
        case "closeApiOperationFinalDialog":
            return {
                ...state,
                ApiOperationFinalDialogState: {
                    ...state.ApiOperationFinalDialogState,
                    IsOpen: false
                }
            };
        case "loadCustomers":
            return {
                ...state,
                Customers: {
                    ...state.Customers,
                    Data: action.Customers,
                    AreLoaded: true
                }
            };
        case "unloadCustomers":
            return {
                ...state,
                Customers: {
                    ...state.Customers,
                    Data: [],
                    AreLoaded: false
                }
            };
        case "setSwipeableSonopetDrawerState": {
            return {
                ...state,
                SwipeableSonopetDrawerState: {
                    ...state.SwipeableSonopetDrawerState,
                    IsOpen: action.IsOpen
                }
            };
        }
        default:
            return state;
    }
};

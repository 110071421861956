import * as Yup from "yup";
import FormikBase from "../../common/components/FormikBase";

export default class Domain extends FormikBase {
  static readonly SchemaWithValidations = Yup.object().shape({
    FirstName: Yup.string()
      .required("Required.")
      .matches(
        /^[a-z A-Z 0-9 _'\-]+$/,
        "FirstName can only use letters, numbers, apostrophes, underscores and hyphens."
      ) 
      .default(""),
    LastName: Yup.string()
      .required("Required.")
      .matches(
        /^[a-z A-Z 0-9 _'\-]+$/,
        "LastName can only use letters, numbers, apostrophes, underscores and hyphens."
      ) 
      .default(""),
    Email: Yup.string()
      .email("Must be a valid email.")
      .required("Required.")
      .default("")
      .test("StrykerEmailOnly", "Should be a valid Stryker email.", function(
        value
      ): boolean {
        if (
          !this.parent.Email.toLowerCase()
            .trim()
            .endsWith("@stryker.com")
        ) {
          return false;
        }
        return true;
      })
  });
}

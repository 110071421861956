import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import PersonIcon from "@material-ui/icons/Person";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { Paper } from "@material-ui/core";
import CustomersList, { CustomersListTypeEnum } from "./CustomersList";
import ApiOperation from "../common/api-operations/ApiOperations";
import { ApiCalls } from "../constants";
import { FormikActions } from "formik";
import { useGlobalState, dispatch } from "../common/global-state/State";

interface TabContainerProps {
  children?: React.ReactNode;
  dir?: string;
}

function TabContainer({ children, dir }: TabContainerProps) {
  return (
    <Typography
      component="div"
      dir={dir}
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 0,
        paddingRight: 0
      }}
    >
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(15)
    },
    hrMain: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2)
    },
    hr: {
      marginTop: theme.spacing(0),
      backgroundColor: "grey",
      color: "grey"
    },
    paper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    tabs: {
      borderBottom: "1px solid rgb(212, 212, 212)"
    }
  })
);

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  function handleChangeIndex(index: number) {
    setValue(index);
  }

  useEffect(() => {
    //Clear previously loaded data
    dispatch({
      type: "unloadCustomers"
    });

    ApiOperation(
      ApiCalls.GetCustomers,
      {},
      false,
      {} as FormikActions<{}>,
      (isSuccess: boolean, data: any) => {
        if (isSuccess) {
          dispatch({
            type: "loadCustomers",
            Customers: data.data.Data
          });
        }
      }
    );
  });

  return (
    <Container component="main" maxWidth="lg" className={classes.root}>
      <Typography color="primary" component="h2" variant="h5">
        Customers
      </Typography>
      <hr className={classes.hrMain} />
      <Paper className={classes.paper} elevation={10}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.tabs}
        >
          <Tab icon={<PersonPinIcon />} label="PENDING APPROVAL" />
          <Tab icon={<PersonIcon />} label="ALL" />
          {/* <Tab icon={<PersonAddIcon />} label="APPROVED" />
          <Tab icon={<PersonOutlineIcon />} label="DISABLED" /> */}
        </Tabs>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            <CustomersList
              CustomersListType={CustomersListTypeEnum.PENDING_APPROVAL}
            />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <CustomersList CustomersListType={CustomersListTypeEnum.ALL} />
          </TabContainer>
          {/* <TabContainer dir={theme.direction}>
            <CustomersList CustomersListType={CustomersListTypeEnum.APPROVED} />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <CustomersList CustomersListType={CustomersListTypeEnum.DISABLED} />
          </TabContainer> */}
        </SwipeableViews>
      </Paper>
    </Container>
  );
}

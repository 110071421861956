import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Drawer,
  MenuList,
  Link,
  MenuItem,
  Typography,
  Grid
} from "@material-ui/core";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LockIcon from "@material-ui/icons/Lock";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useGlobalState, dispatch } from "../global-state/State";
import { NavLink } from "react-router-dom";
import { AppPaths } from "../../constants";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HelpIcon from "@material-ui/icons/Help";
import { CurrentUser_Get } from "../components/CurrentUser";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 245
    }
  })
);

export default function SwipeableSonopetDrawer() {
  const classes = useStyles();
  const [SwipeableSonopetDrawerState] = useGlobalState(
    "SwipeableSonopetDrawerState"
  );

  return (
    <Drawer
      open={SwipeableSonopetDrawerState.IsOpen}
      onClose={() => {
        dispatch({
          type: "setSwipeableSonopetDrawerState",
          IsOpen: !SwipeableSonopetDrawerState.IsOpen
        });
      }}
      transitionDuration={{ enter: 500, exit: 100 }}
    >
      <div className={classes.list} role="presentation">
        <Divider />
        <Typography
          align="center"
          variant="h5"
          color="primary"
          style={{ backgroundColor: "#545857" }}
        >
          <strong>KeyGen</strong>
        </Typography>
        <Divider />
        {!CurrentUser_Get().IsLoggedIn && (
          <MenuList>
            {[
              {
                text: "Sign In",
                path: AppPaths.SignIn
                // visible: !CurrentUser_Get().IsLoggedIn
              },
              {
                text: "Sign Up",
                path: AppPaths.SignUp
              },
              {
                text: "Resend Verification (get OTP)",
                path: AppPaths.ResendVerification
              },
              {
                text: "Verify Account (with OTP)",
                path: AppPaths.VerifyAccount
              },
              {
                text: "Recover Password (get OTP)",
                path: AppPaths.RecoverPassword
              },
              {
                text: "Reset Password (with OTP)",
                path: AppPaths.ResetPassword
              }
            ].map((menuItem, index) => (
              <NavLink
                to={menuItem.path}
                style={{ textDecoration: "none", color: "black" }}
                key={menuItem.text}
                onClick={() => {
                  dispatch({
                    type: "setSwipeableSonopetDrawerState",
                    IsOpen: !SwipeableSonopetDrawerState.IsOpen
                  });
                }}
                activeStyle={{ color: "#ffb500" }}
              >
                <MenuItem>
                  <Grid container alignItems="center">
                    {/* <Grid item xs={2}>
                    <LockOutlinedIcon color="primary" />
                  </Grid>  */}
                    <Grid item xs container alignItems="center">
                      {menuItem.text}
                    </Grid>
                  </Grid>
                </MenuItem>
              </NavLink>
            ))}
          </MenuList>
        )}
        {CurrentUser_Get().IsLoggedIn && (
          <div>
            <Typography variant="subtitle1" color="primary">
              <strong>&nbsp;Hi, {CurrentUser_Get().FirstName}</strong>
            </Typography>
          </div>
        )}
        {CurrentUser_Get().IsLoggedIn && (
          <div>
            <MenuList>
              {CurrentUser_Get().Roles.some(
                (role: any) => role.toLowerCase() === "administrator"
              ) &&
                [
                  { text: "Manage Customers", path: AppPaths.ManageCustomers }
                ].map((menuItem, index) => (
                  <NavLink
                    to={menuItem.path}
                    style={{ textDecoration: "none", color: "black" }}
                    key={menuItem.text}
                    onClick={() => {
                      dispatch({
                        type: "setSwipeableSonopetDrawerState",
                        IsOpen: !SwipeableSonopetDrawerState.IsOpen
                      });
                    }}
                    activeStyle={{ color: "#ffb500" }}
                  >
                    {" "}
                    <MenuItem key={menuItem.text}>
                      <Grid container alignItems="center">
                        <Grid item xs={2} container alignItems="center">
                          <PersonIcon color="primary" />
                        </Grid>
                        <Grid item xs container alignItems="center">
                          {menuItem.text}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </NavLink>
                ))}
              {[{ text: "Generate Key", path: AppPaths.KeyGenerator }].map(
                (menuItem, index) => (
                  <NavLink
                    to={menuItem.path}
                    style={{ textDecoration: "none", color: "black" }}
                    key={menuItem.text}
                    onClick={() => {
                      dispatch({
                        type: "setSwipeableSonopetDrawerState",
                        IsOpen: !SwipeableSonopetDrawerState.IsOpen
                      });
                    }}
                    activeStyle={{ color: "#ffb500" }}
                  >
                    <MenuItem key={menuItem.text}>
                      <Grid container alignItems="center">
                        <Grid item xs={2} container alignItems="center">
                          <VpnKeyIcon color="primary" />
                        </Grid>
                        <Grid item xs container alignItems="center">
                          {menuItem.text}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </NavLink>
                )
              )}
              {CurrentUser_Get().Roles.some(
                (role: any) => role.toLowerCase() === "administrator"
              ) &&
                [
                  {
                    text: "Invite Administrator",
                    path: AppPaths.InviteAdministrator
                  }
                ].map((menuItem, index) => (
                  <NavLink
                    to={menuItem.path}
                    style={{ textDecoration: "none", color: "black" }}
                    key={menuItem.text}
                    onClick={() => {
                      dispatch({
                        type: "setSwipeableSonopetDrawerState",
                        IsOpen: !SwipeableSonopetDrawerState.IsOpen
                      });
                    }}
                    activeStyle={{ color: "#ffb500" }}
                  >
                    {" "}
                    <MenuItem key={menuItem.text}>
                      <Grid container alignItems="center">
                        <Grid item xs={2} container alignItems="center">
                          <PersonAddIcon color="primary" />
                        </Grid>
                        <Grid item xs container alignItems="center">
                          {menuItem.text}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </NavLink>
                ))}
            </MenuList>
          </div>
        )}
        <Divider />
        <MenuList>
          {[{ text: "Help", path: AppPaths.Help }].map((menuItem, index) => (
            <NavLink
              to={menuItem.path}
              style={{ textDecoration: "none", color: "black" }}
              key={menuItem.text}
              onClick={() => {
                dispatch({
                  type: "setSwipeableSonopetDrawerState",
                  IsOpen: !SwipeableSonopetDrawerState.IsOpen
                });
              }}
              activeStyle={{ color: "#ffb500" }}
            >
              {" "}
              <MenuItem key={menuItem.text}>
                <Grid container alignItems="center">
                  <Grid item xs={2} container alignItems="center">
                    <HelpIcon color="primary" />
                  </Grid>
                  <Grid item xs container alignItems="center">
                    {menuItem.text}
                  </Grid>
                </Grid>
              </MenuItem>
            </NavLink>
          ))}
          {CurrentUser_Get().IsLoggedIn &&
            [
              {
                text: "Sign Out",
                path: AppPaths.SignOut
              }
            ].map((menuItem, index) => (
              <NavLink
                to={menuItem.path}
                style={{ textDecoration: "none", color: "black" }}
                key={menuItem.text}
                onClick={() => {
                  dispatch({
                    type: "setSwipeableSonopetDrawerState",
                    IsOpen: !SwipeableSonopetDrawerState.IsOpen
                  });
                }}
                activeStyle={{ color: "#ffb500" }}
              >
                <MenuItem>
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <LockIcon color="primary" />
                    </Grid>
                    <Grid item xs container alignItems="center">
                      {menuItem.text}
                    </Grid>
                  </Grid>
                </MenuItem>
              </NavLink>
            ))}
        </MenuList>
      </div>
    </Drawer>
  );
}

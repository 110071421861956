import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Grid, Link } from "@material-ui/core";
// import IconTwitter from "../../common/images/icon-social-t.png";
// import IconLinkedIn from "../../common/images/icon-social-ln.png";
// import IconFacebook from "../../common/images/icon-social-f.png";
// import IconYouTube from "../../common/images/icon-social-yt.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    footerAppBar: {
      top: "auto",
      bottom: 0
    }
  })
);

export default function Footer(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar color="secondary" className={classes.footerAppBar}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item sm={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                &nbsp;&copy;2019 Stryker v1.0.3
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid container justify="flex-end">
              <Link href="https://www.stryker.com/" target="_blank">
                Stryker.com
              </Link>
              &nbsp;
            </Grid>
          </Grid>
          {/* <Grid item sm={6}>
            <Grid container justify="flex-end">
              <img src={IconTwitter} alt="Twitter" />
              &nbsp;&nbsp;
              <img src={IconLinkedIn} alt="LinkedIn" />
              &nbsp;&nbsp;
              <img src={IconFacebook} alt="Facebook" />
              &nbsp;&nbsp;
              <img src={IconYouTube} alt="YouTube" />
            </Grid>
          </Grid> */}
        </Grid>
      </AppBar>
    </div>
  );
}

import * as Yup from "yup";
import FormikBase from "../../common/components/FormikBase";

export default class Domain extends FormikBase {
  static readonly SchemaWithValidations = Yup.object().shape({
    Email: Yup.string()
      .email("Must be a valid email.")
      .required("Required.")
       .matches(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,"Invalid Email address.")
      .default(""),
    Password: Yup.string()
      .required("Required.")
      .test(
        "NonStrykerPasswordComplexity",
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character.",
        function(value): boolean {
          if (
            !this.parent.Email.toLowerCase()
              .trim()
              .endsWith("@stryker.com")
          ) {
            if (
              !/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                value
              )
            ) {
              return false;
            }
          }
          return true;
        }
      )
      .default("")
  });
}
